import { ethers } from "ethers";
import UfcAbi from "../../abis/ufc.json";
import { gameConfig } from "../../config";
import { FCEventType } from "../../constants/ufc";
import { UFCRoundStatusDetails } from "../../reducers/ufc.slice";

import {
  approveToken,
  getBetTokens,
  getProvider,
  getTokenAllowance,
  getWeb3,
} from "./index";

export const getUFCContractAddress = (eventType: number) => {
  switch (eventType) {
    case FCEventType.IFC:
      return gameConfig.ifcAddress;
    case FCEventType.UFC299:
      return gameConfig.ufcAddress;
    case FCEventType.UFC300:
      return gameConfig.ufc300Address;
    case FCEventType.DAZN:
      return gameConfig.daznAddress;
    case FCEventType.ELECTION:
      return gameConfig.electionAddress;
    case FCEventType.TYSON:
      return gameConfig.tysonAddress;
  }

  return "";
};

export const getUFCContract = (eventType: number, provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = getUFCContractAddress(eventType);
  const UFCContract = new web3.eth.Contract(UfcAbi, contractAddress);
  return UFCContract;
};

export const getUFCBetTokens = async (eventType: number) => {
  const UFCContract = getUFCContract(eventType);

  return await getBetTokens(UFCContract);
};

export const getRoundInfoStatus = async (
  eventType: number,
  roundId: number
) => {
  const NightFightContract = getUFCContract(eventType);
  const roundInfo: UFCRoundStatusDetails = await NightFightContract.methods
    .rounds(roundId)
    .call();
  const parsedRoundInfo = {
    closeAt: Number(roundInfo.closeAt),
    status: Number(roundInfo.status),
    finishAt: Number(roundInfo.finishAt),
    result: Number(roundInfo.result),
  };

  return { roundInfo: parsedRoundInfo };
};

export const enterRound = async (
  eventType: number,
  roundId: number,
  tokenId: number,
  tokenAmount: number,
  tokenAddress: string,
  usdAmount: number,
  expectation: number,
  account: string
) => {
  const NightFightContract = getUFCContract(eventType, true);
  const allowance = await getTokenAllowance(
    tokenAddress,
    account,
    getUFCContractAddress(eventType)
  );
  const formattedUsdAmount = ethers.utils
    .parseUnits(usdAmount.toString(), 18)
    .toString();

  if (Number(allowance) < tokenAmount) {
    await approveToken(
      tokenAddress,
      tokenAmount,
      getUFCContractAddress(eventType),
      account
    );

    // Wait for a few seconds after approval
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds
  }

  const data = await NightFightContract.methods
    .enterRound(roundId, tokenId, formattedUsdAmount, expectation)
    .send({
      from: account,
    });

  return data;
};

// (uint256 roundId, uint256 tokenId, uint256 usdAmount, uint256 expectation)
export const enterRoundWithPLS = async (
  eventType: number,
  roundId: number,
  tokenId: number,
  plsAmount: number,
  usdAmount: number,
  expectation: number,
  account: string
) => {
  const NightFightContract = getUFCContract(eventType, true);
  const decimals = 18;
  const formattedPlsAmount = ethers.utils
    .parseUnits(plsAmount.toFixed(18), decimals)
    .toString();

  const formattedUsdAmount = ethers.utils
    .parseUnits(usdAmount.toString(), 18)
    .toString();

  const data = await NightFightContract.methods
    .enterRound(roundId, tokenId, formattedUsdAmount, expectation)
    .send({
      from: account,
      value: formattedPlsAmount,
    });

  return data;
};
