import { ethers } from "ethers";
import { FCEventType } from "../constants/ufc";
import { gameConfig } from "../config";

export const formatUFCEnteredRoundInfo = (event: any) => {
  return {
    roundId: Number(event.result.returnValues.roundId),
    entryId: Number(event.result.returnValues.entryId),
    player: event.result.returnValues.player,
    tokenName: event.result.returnValues.tokenName,
    tokenAmount: Number(
      ethers.utils.formatEther(event.result.returnValues.tokenAmount)
    ),
    usdAmount: Number(
      ethers.utils.formatEther(event.result.returnValues.usdAmount)
    ),
    expectation: Number(event.result.returnValues.expectation),
    timestamp: Number(event.result.returnValues.timestamp),
  };
};

export const formatUFCRoundFinishedInfo = (event: any) => {
  return {
    roundId: Number(event.result.returnValues.roundId),
    result: Number(event.result.returnValues.result),
    winner: event.result.returnValues.winner,
    usdAmount: Number(
      ethers.utils.formatEther(event.result.returnValues.usdAmount)
    ),
    fee: Number(ethers.utils.formatEther(event.result.returnValues.fee)),
    plspBurnt: Number(
      ethers.utils.formatEther(event.result.returnValues.plspBurnt)
    ),
    timestamp: Number(event.result.returnValues.timestamp),
  };
};

export const formatUFCRoundCancelledInfo = (event: any) => {
  return {
    roundId: Number(event.result.returnValues.roundId),
    timestamp: Number(event.result.returnValues.timestamp),
  };
};

export function getRoundId(roundId: number, eventType: number) {
  if (eventType === FCEventType.IFC) return roundId;
  else if (eventType === FCEventType.DAZN) return 3 - roundId;
  else if (eventType === FCEventType.ELECTION) return 1 - roundId;
  else if (eventType === FCEventType.TYSON) return 4 - roundId;
  else return 5 - roundId;
}

export function getServer(eventType: number) {
  switch (eventType) {
    case FCEventType.IFC:
    case FCEventType.UFC299:
    case FCEventType.ELECTION:
    case FCEventType.UFC300:
    case FCEventType.DAZN:
    case FCEventType.TYSON:
      return gameConfig.serverUrl;
  }

  return "";
}

export function getEndpoint(eventType: number) {
  switch (eventType) {
    case FCEventType.IFC:
      return "ifc";
    case FCEventType.UFC299:
      return "karate";
    case FCEventType.UFC300:
      return "ufc/300";
    case FCEventType.DAZN:
      return "dazn";
    case FCEventType.ELECTION:
      return "ufc/election";
    case FCEventType.TYSON:
      return "ufc/fight";
  }
}

export function getSSEEndpoint(eventType: number) {
  switch (eventType) {
    case FCEventType.IFC:
      return "event/ifc";
    case FCEventType.UFC299:
      return "event/karate";
    case FCEventType.UFC300:
      return "event/ufc";
    case FCEventType.DAZN:
      return "event/dazn";
    case FCEventType.ELECTION:
      return "event/ufc";
    case FCEventType.TYSON:
      return "event/ufc";
  }
}
