import { makeStyles } from "tss-react/mui";

const nightFightArrowAnimation = {
  "0%": {
    bottom: "-48px",
  },
  "50%": {
    bottom: "-32px",
  },
  "100%": {
    bottom: "-48px",
  },
};

const styles = makeStyles()((theme: any) => ({
  UFCRoundSwitch: {
    width: "100%",
    color: "#7a7d8b",
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      "& span": {
        fontSize: "11px",
      },
    },
  },
  UFCRoundSwitchDAZN: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  UFCRoundSwitchElection: {
    gridTemplateColumns: "1fr",
    justifyItems: "center",

    "&>div": {
      maxWidth: 360,
      minWidth: 320,
    },
  },

  UFCRoundSwitchTyson: {
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },

  nightFightRound: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid #2d2e36",
    backgroundColor: "#1b1f28",
    gap: "4px",
    paddingTop: "24px",
    paddingBottom: "24px",
    flexGrow: "1",
    transition: "300ms",
    userSelect: "none",
    position: "relative",
    cursor: "pointer",
    "&>span": {
      textAlign: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "block",
      overflow: "hidden",
      maxWidth: "14vw",
    },
    "&:first-of-type": {
      borderBottomLeftRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "&:last-of-type": {
      border: "none",
      borderBottomRightRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "&:hover": {
      backgroundColor: "#252c3d",
    },
  },
  nightFightRoundMultipliers: {
    display: "flex",
    gap: "12px",
    [theme.breakpoints.down("sm")]: {
      gap: "2px",
    },
  },
  selected: {
    backgroundColor: "#26293c",
    color: "white",
  },
  player1: {
    color: "#cd72ee",
  },
  player2: {
    color: "#21acf1",
  },
  nightFightRoundTitle: {
    fontWeight: "600",
    fontSize: "18px",
    marginBottom: "4px",
    "&.selected": {
      color: "#cd72ee",
    },
    [theme.breakpoints.down("sm")]: {
      fontsize: "16px",
    },
  },
  nightFightRoundDetail: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    [theme.breakpoints.down("sm")]: {
      gap: "2px",
      alignItems: "center",
    },
  },
  multiplier: {
    borderRadius: "8px",
    padding: "2px 8px",
    fontSize: "14px",
    fontWeight: "600",
    backgroundColor: "#434551",
    [theme.breakpoints.down("sm")]: {
      padding: "2px 4px",
      fontSize: "12px",
    },
    "&.selected": {
      backgroundColor: "#393b47",
    },
  },
  nightFightRoundPool: {
    position: "absolute",
    top: "-32px",
    backgroundColor: "#343532",
    borderRadius: "8px",
    padding: "2px 8px",
    color: "#dcdb97",
    fontWeight: "600",
    textShadow:
      "0px 0px 20px #e7a426, 0px 0px 40px #e7a426, 0px 0px 60px #e7a426, 0px 0px 80px #e7a426, 0px 0px 100px #e7a426",
    [theme.breakpoints.down("sm")]: {
      top: "-28px",
    },
  },
  selectedIndicator: {
    position: "absolute",
    bottom: "-32px",
    animation: "nightFightArrowAnimation 1s infinite ease-in-out",
    "@keyframes nightFightArrowAnimation": nightFightArrowAnimation,
    "&>img": {
      width: "32px",
      height: "32px",
      transform: "rotate(270deg)",
    },
  },
}));

export default styles;
