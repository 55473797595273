import { Box, Button, CircularProgress, Skeleton } from "@mui/material";
import Popover from "@mui/material/Popover";
import BigNumber from "bignumber.js";
import clsx from "clsx";
import { utils } from "ethers";
import { useEffect, useState } from "react";
import Web3 from "web3";

import AnimateDiv from "../AnimateDiv";
import TokenImage from "../TokenImage";
import TokenListPopup from "../TokenListPopup";

import { gameConfig } from "../../config/index";

import {
  BET_APPROVE_OFFSET,
  FCEventType,
  IFCFightRoundInfos,
  UFC299FightRoundInfos,
  UFC300FightRoundInfos,
  DAZNFightRoundInfo,
  UFCPlayerType,
  UFCRoundResult,
  UFCRoundStatus,
  ELECTIONRoundInfo,
  TysonFightRoundInfo,
} from "../../constants/ufc";

import {
  clearNewRoundDetails,
  enterUFCRound,
  getTokenBalances,
  setLastBettedUsdAmount,
  setNewRoundToken,
  setNewRoundUSD,
  setSelectedPlayer,
  setSelectedTokenBalance,
  setSelectedTokenUsdValue,
  setShowBetCompleteModal,
  setShowBettingInput,
} from "../../reducers/ufc.slice";

import { toUSDFormat } from "../../utils";

import ticketIcon from "../../assets/images/ufc/icon_ticket.png";
import trophyIcon from "../../assets/images/ufc/icon_trophy.png";
import vsBorder from "../../assets/images/ufc/vs_border.png";
import xIcon from "../../assets/images/ufc/x.png";

import { useConnectWallet } from "@web3-onboard/react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import useStyles from "./index.styles";

interface UFCRoundInfoProps {
  eventType: number;
}

export default function UFCRoundInfo(props: UFCRoundInfoProps) {
  const { classes } = useStyles();
  const { eventType } = props;
  const dispatch = useAppDispatch();
  const [{ wallet }, connect] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";

  const {
    betTokens,
    currentRound,
    selectedPlayer,
    showBettingInput,
    isEntering,
    currentRoundStatus,
    currentRoundDetails,
    loadingRoundDetails,
    newRoundDetails,
  } = useAppSelector((state) => state.ufc);

  const [usdValue, setUsdValue] = useState(0.0);
  const [tokenSelected, setTokenSelected] = useState(false);
  const [usdInputted, setUsdInputted] = useState(false);

  const [popupAnchor, setPopupAnchor] = useState(null);
  const popOpen = Boolean(popupAnchor);
  const popupId = popOpen ? "simple-popover" : undefined;

  const connectWallet = async () => {
    const wallets = await connect();
    const web3 = new Web3(wallets[0].provider);
    (window as any).provider = web3;
  };

  const handlePopover = (e: any) => {
    setPopupAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setPopupAnchor(null);
  };

  const handleInputChange = (e: any) => {
    setUsdValue(e.target.value);
    dispatch(setNewRoundUSD(parseFloat(e.target.value)));
  };

  const handlePlaceBetClick = (player: number) => {
    if (!account) {
      connectWallet();
      return;
    }
    dispatch(setShowBettingInput(true));
    dispatch(setSelectedPlayer(player));
  };

  const handleConfirmClick = async () => {
    if (!tokenSelected || !usdInputted) return;

    const tokenId = betTokens.findIndex((token, index) => {
      return token.name === newRoundDetails.selectedToken;
    });

    const parsedUnits = new BigNumber(
      Number(utils.parseUnits(usdValue.toString(), 18))
    );
    const price = new BigNumber(betTokens[tokenId].price * 10 ** 18);

    const tokenAmount = parsedUnits
      .div(price)
      .times(100 + (betTokens[tokenId].isStable ? 0 : BET_APPROVE_OFFSET))
      .div(100);

    dispatch(
      enterUFCRound({
        eventType,
        tokenId,
        roundId: currentRound,
        tokenAmount: Number(tokenAmount),
        tokenAddress: betTokens[tokenId].tokenAddress,
        usdAmount: usdValue,
        expectation: selectedPlayer,
        account: account,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getTokenBalances(account));
        dispatch(setLastBettedUsdAmount(usdValue));
        dispatch(clearNewRoundDetails());
        dispatch(setShowBetCompleteModal(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMaxBet = () => {
    setUsdValue(parseFloat(newRoundDetails.selectedTokenUsdValue.toFixed(2)));
    dispatch(
      setNewRoundUSD(
        parseFloat(newRoundDetails.selectedTokenUsdValue.toFixed(2))
      )
    );
  };

  const handleClearBet = () => {
    setUsdValue(0);
    dispatch(setNewRoundUSD(0));
  };

  const handleQuickBetClick = (amount: number) => {
    const currentUsdValue = usdValue ? parseFloat(usdValue.toString()) : 0;
    setUsdValue(currentUsdValue + amount);
    dispatch(setNewRoundUSD(currentUsdValue + amount));
  };

  const handleViewTxClick = () => {
    window.open(
      `${gameConfig.explorerUrl}tx/${currentRoundDetails.txHash}`,
      "_blank"
    );
  };

  const handleSelectToken = (
    tokenName: string,
    tokenBalance: number,
    tokenUSDValue: number
  ) => {
    dispatch(setNewRoundToken(tokenName));
    dispatch(setSelectedTokenBalance(tokenBalance));
    dispatch(setSelectedTokenUsdValue(tokenUSDValue));
  };

  useEffect(() => {
    setUsdInputted(newRoundDetails.usdValue > 0);
  }, [newRoundDetails.usdValue]);

  useEffect(() => {
    handleClose();
    if (newRoundDetails.selectedToken === "") {
      setTokenSelected(false);
      return;
    }

    setTokenSelected(true);
  }, [newRoundDetails.selectedToken]);

  const renderTokenSelection = () => {
    return (
      <Button className={classes.ufcRoundToken} onClick={handlePopover}>
        {tokenSelected ? (
          <TokenImage token={newRoundDetails.selectedToken} />
        ) : (
          "?"
        )}

        <Box className={classes.ufcRoundTokenSelectedIndicator}>
          <img src="/images/icons/indicator.png" alt="" srcSet="" />
        </Box>
      </Button>
    );
  };

  const renderInputSection = (isReadOnly = false, isEditing = false) => {
    return (
      <Box className={classes.ufcInputWithPrefix}>
        <span>$</span>
        <input
          max={newRoundDetails.selectedTokenUsdValue}
          type="number"
          value={usdValue}
          onChange={handleInputChange}
          className="bet-usd-input"
        />

        <span className="bet-usd-clear" onClick={handleClearBet}>
          Clear
        </span>

        <span className="bet-usd-max-label" onClick={handleMaxBet}>
          Max ${toUSDFormat(newRoundDetails.selectedTokenUsdValue)}
        </span>
      </Box>
    );
  };

  const renderQuickBet = (value: number) => {
    return (
      <Button
        className={classes.ufcRoundQuickBet}
        onClick={() => {
          handleQuickBetClick(value);
        }}
      >
        +{value}$
      </Button>
    );
  };

  const renderCreateButton = () => {
    return (
      <Button
        className={clsx(
          classes.ufcRoundButton,
          classes.ufcRoundBetButton,
          classes.createButton
        )}
        onClick={handleConfirmClick}
        disabled={isEntering || !tokenSelected || !usdInputted}
      >
        {isEntering ? (
          <CircularProgress
            color="inherit"
            className={classes.ufcLoadingSpinner}
          />
        ) : (
          "Create"
        )}
      </Button>
    );
  };

  const renderRoundAction = (player: number) => {
    switch (currentRoundStatus.status) {
      case UFCRoundStatus.STARTED:
        return (
          <Box
            className={clsx(
              classes.ufcRoundActions,
              player === UFCPlayerType.PLAYER2 ? "right" : ""
            )}
          >
            {showBettingInput && player === selectedPlayer ? (
              <AnimateDiv style={{ display: "flex", gap: "8px" }} small>
                {renderTokenSelection()}
                {renderInputSection()}
                {renderQuickBet(5)}
                {renderQuickBet(25)}
                {renderCreateButton()}
              </AnimateDiv>
            ) : (
              <Button
                className={clsx(
                  classes.ufcRoundButton,
                  classes.ufcRoundBetButton
                )}
                onClick={() => {
                  handlePlaceBetClick(player);
                }}
              >
                <img src={ticketIcon} alt="" /> Place Bet
              </Button>
            )}
          </Box>
        );
      case UFCRoundStatus.CLOSED:
        return (
          <Box
            className={clsx(
              classes.ufcRoundActions,
              player === UFCPlayerType.PLAYER2 ? "right" : ""
            )}
          >
            <Button
              className={clsx(
                classes.ufcRoundButton,
                classes.ufcRoundClosedButton
              )}
            >
              <img src={ticketIcon} alt="" /> Closed
            </Button>
          </Box>
        );
      case UFCRoundStatus.FINISHING:
        return (
          <Box
            className={clsx(
              classes.ufcRoundActions,
              player === UFCPlayerType.PLAYER2 ? "right" : ""
            )}
          >
            <Button
              className={clsx(
                classes.ufcRoundButton,
                classes.ufcRoundFinishedButton
              )}
            >
              <img src={ticketIcon} alt="" /> Finishing
            </Button>
          </Box>
        );
      case UFCRoundStatus.FINISHED:
        return (
          <Box
            className={clsx(
              classes.ufcRoundActions,
              player === UFCPlayerType.PLAYER2 ? "right" : ""
            )}
          >
            <Button
              className={clsx(
                classes.ufcRoundButton,
                classes.ufcRoundFinishedButton
              )}
            >
              <img src={ticketIcon} alt="" /> Finished
            </Button>
            {player === currentRoundStatus.result ||
            currentRoundStatus.result === 2 ? (
              <Button
                className={clsx(
                  classes.ufcRoundButton,
                  classes.ufcRoundFinishedTx
                )}
                onClick={handleViewTxClick}
              >
                <img src={trophyIcon} alt="" /> View Transaction
              </Button>
            ) : (
              ""
            )}
          </Box>
        );
      case UFCRoundStatus.CANCELING:
        return (
          <Box
            className={clsx(
              classes.ufcRoundActions,
              player === UFCPlayerType.PLAYER2 ? "right" : ""
            )}
          >
            <Button
              className={clsx(
                classes.ufcRoundButton,
                classes.ufcRoundClosedButton
              )}
            >
              <img src={ticketIcon} alt="" /> CANCELING
            </Button>
          </Box>
        );
      case UFCRoundStatus.CANCELLED:
        return (
          <Box
            className={clsx(
              classes.ufcRoundActions,
              player === UFCPlayerType.PLAYER2 ? "right" : ""
            )}
          >
            <Button
              className={clsx(
                classes.ufcRoundButton,
                classes.ufcRoundClosedButton
              )}
            >
              <img src={ticketIcon} alt="" /> CANCELLED
            </Button>
            {currentRoundStatus.result === 2 && (
              <Button
                className={clsx(
                  classes.ufcRoundButton,
                  classes.ufcRoundFinishedTx
                )}
                onClick={handleViewTxClick}
              >
                <img src={trophyIcon} alt="" /> View Transaction
              </Button>
            )}
          </Box>
        );

      default:
        break;
    }
  };
  const renderWinnerTag = (player: number) => {
    if (currentRoundStatus.status === UFCRoundStatus.FINISHED) {
      if (player === currentRoundStatus.result) {
        return (
          <span
            className={clsx(
              classes.ufcWinnerTag,
              player === UFCPlayerType.PLAYER2 ? "right" : ""
            )}
          >
            Winner!
          </span>
        );
      } else if (currentRoundStatus.result === UFCRoundResult.DRAW) {
        return (
          <span
            className={clsx(
              classes.ufcDrawTag,
              player === UFCPlayerType.PLAYER2 ? "right" : ""
            )}
          >
            Draw!
          </span>
        );
      }
    }
  };

  const renderPlayerInfo = (player: number) => {
    switch (eventType) {
      case FCEventType.UFC299:
        return (
          <>
            <span className="player-social">
              <span>
                {player === UFCPlayerType.PLAYER1
                  ? UFC299FightRoundInfos[currentRound].player1.record
                  : UFC299FightRoundInfos[currentRound].player2.record}{" "}
                {UFC299FightRoundInfos[currentRound].weight}
              </span>
            </span>
            <span className="player-name">
              {player === UFCPlayerType.PLAYER1
                ? UFC299FightRoundInfos[currentRound].player1.name
                : UFC299FightRoundInfos[currentRound].player2.name}
            </span>
          </>
        );
      case FCEventType.UFC300:
        return (
          <>
            <span className="player-social">
              <span>
                {player === UFCPlayerType.PLAYER1
                  ? UFC300FightRoundInfos[currentRound].player1.record
                  : UFC300FightRoundInfos[currentRound].player2.record}{" "}
                {UFC300FightRoundInfos[currentRound].weight}
              </span>
            </span>
            <span className="player-name">
              {player === UFCPlayerType.PLAYER1
                ? UFC300FightRoundInfos[currentRound].player1.name
                : UFC300FightRoundInfos[currentRound].player2.name}
            </span>
          </>
        );
      case FCEventType.IFC:
        return (
          <>
            <span className="player-social">
              <a
                href={
                  player === UFCPlayerType.PLAYER1
                    ? IFCFightRoundInfos[currentRound].player1.twitter
                    : IFCFightRoundInfos[currentRound].player2.twitter
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={xIcon} alt="" srcSet="" />
              </a>
              <span>
                W0 - D0 - L0 {IFCFightRoundInfos[currentRound].weight}
              </span>
            </span>
            <span className="player-name">
              {player === UFCPlayerType.PLAYER1
                ? IFCFightRoundInfos[currentRound].player1.name
                : IFCFightRoundInfos[currentRound].player2.name}
            </span>
          </>
        );
      case FCEventType.DAZN:
        return (
          <>
            <span className="player-social">
              <span>
                {player === UFCPlayerType.PLAYER1
                  ? DAZNFightRoundInfo[currentRound].player1.record
                  : DAZNFightRoundInfo[currentRound].player2.record}{" "}
                {DAZNFightRoundInfo[currentRound].weight}
              </span>
            </span>
            <span className="player-name">
              {player === UFCPlayerType.PLAYER1
                ? DAZNFightRoundInfo[currentRound].player1.name
                : DAZNFightRoundInfo[currentRound].player2.name}
            </span>
          </>
        );

      case FCEventType.ELECTION:
        return (
          <>
            <span className="player-social">
              <span>
                {player === UFCPlayerType.PLAYER1
                  ? ELECTIONRoundInfo[currentRound].player1.record
                  : ELECTIONRoundInfo[currentRound].player2.record}{" "}
              </span>
            </span>
            <span className="player-name">
              {player === UFCPlayerType.PLAYER1
                ? ELECTIONRoundInfo[currentRound].player1.name
                : ELECTIONRoundInfo[currentRound].player2.name}
            </span>
          </>
        );

      case FCEventType.TYSON:
        return (
          <>
            <span className="player-social">
              <span>
                {player === UFCPlayerType.PLAYER1
                  ? TysonFightRoundInfo[currentRound].player1.record
                  : TysonFightRoundInfo[currentRound].player2.record}{" "}
                {TysonFightRoundInfo[currentRound].weight}
              </span>
            </span>
            <span className="player-name">
              {player === UFCPlayerType.PLAYER1
                ? TysonFightRoundInfo[currentRound].player1.name
                : TysonFightRoundInfo[currentRound].player2.name}
            </span>
          </>
        );
      default:
        break;
    }
  };

  const renderPlayerPhoto = (player: number) => {
    switch (eventType) {
      case FCEventType.UFC300:
        return (
          <img
            src={`/images/events/ufc300/fighters/${
              player === UFCPlayerType.PLAYER1
                ? UFC300FightRoundInfos[currentRound].player1.photo
                : UFC300FightRoundInfos[currentRound].player2.photo
            }`}
            alt=""
            srcSet=""
            className="player-photo"
          />
        );
      case FCEventType.UFC299:
        return (
          <img
            src={`/images/events/ufc/fighters/${
              player === UFCPlayerType.PLAYER1
                ? UFC299FightRoundInfos[currentRound].player1.photo
                : UFC299FightRoundInfos[currentRound].player2.photo
            }`}
            alt=""
            srcSet=""
            className="player-photo"
          />
        );
      case FCEventType.IFC:
        return (
          <img
            src={`/images/events/ifc/fighters/${
              player === UFCPlayerType.PLAYER1
                ? IFCFightRoundInfos[currentRound].player1.photo
                : IFCFightRoundInfos[currentRound].player2.photo
            }`}
            alt=""
            srcSet=""
            className="player-photo"
          />
        );
      case FCEventType.DAZN:
        return (
          <img
            src={`/images/events/dazn/${
              player === UFCPlayerType.PLAYER1
                ? DAZNFightRoundInfo[currentRound].player1.photo
                : DAZNFightRoundInfo[currentRound].player2.photo
            }`}
            alt=""
            srcSet=""
            className="player-photo"
            style={{
              width: "300px",
              marginTop: "30px",
            }}
          />
        );

      case FCEventType.ELECTION:
        return (
          <img
            src={`/images/events/election/${
              player === UFCPlayerType.PLAYER1
                ? ELECTIONRoundInfo[currentRound].player1.photo
                : ELECTIONRoundInfo[currentRound].player2.photo
            }`}
            alt=""
            srcSet=""
            className="player-photo"
            style={{
              width: "300px",
              marginTop: "30px",
            }}
          />
        );
      case FCEventType.TYSON:
        return (
          <img
            src={`/images/events/tyson/${
              player === UFCPlayerType.PLAYER1
                ? TysonFightRoundInfo[currentRound].player1.photo
                : TysonFightRoundInfo[currentRound].player2.photo
            }`}
            alt=""
            srcSet=""
            className="player-photo"
            style={{
              width: "300px",
              marginTop: "30px",
            }}
          />
        );
      default:
        break;
    }
  };

  const renderEventSummary = () => {
    switch (eventType) {
      case FCEventType.UFC300:
        return (
          <Box className="ufc-round-vs">
            <span>UFC 300</span>
            <Box className="vs">
              <img src={vsBorder} alt="" srcSet="" />
              <span>VS</span>
              <img src={vsBorder} alt="" srcSet="" />
            </Box>
            <span>Sun, Apr 14 / 4:00 AM GMT+2</span>
          </Box>
        );

      case FCEventType.UFC299:
        return (
          <Box className="ufc-round-vs">
            <span>UFC 299</span>
            <Box className="vs">
              <img src={vsBorder} alt="" srcSet="" />
              <span>VS</span>
              <img src={vsBorder} alt="" srcSet="" />
            </Box>
            <span>Sat, Mar 9 / 22:00 EST</span>
          </Box>
        );

      case FCEventType.IFC:
        return (
          <Box className="ufc-round-vs">
            <span>ICF KC44</span>
            <div className="vs">
              <img src={vsBorder} alt="" srcSet="" />
              <span>VS</span>
              <img src={vsBorder} alt="" srcSet="" />
            </div>
            <span>FEB. 23RD 6PM CST</span>
          </Box>
        );

      case FCEventType.DAZN:
        return (
          <Box className="ufc-round-vs">
            <span>DAZN</span>
            <div className="vs">
              <img src={vsBorder} alt="" srcSet="" />
              <span>VS</span>
              <img src={vsBorder} alt="" srcSet="" />
            </div>
            <span>MAY. 18TH 5PM UTC</span>
          </Box>
        );
      case FCEventType.ELECTION:
        return (
          <Box className="ufc-round-vs">
            <span>United states presidential election 2024</span>
            <div className="vs">
              <img src={vsBorder} alt="" srcSet="" />
              <span>VS</span>
              <img src={vsBorder} alt="" srcSet="" />
            </div>
            <span>Nov. 5th, 2024</span>
          </Box>
        );

      case FCEventType.TYSON:
        return (
          <Box className="ufc-round-vs">
            <span>Jake Paul vs. Mike Tyson</span>
            <div className="vs">
              <img src={vsBorder} alt="" srcSet="" />
              <span>VS</span>
              <img src={vsBorder} alt="" srcSet="" />
            </div>
            <span>November 15, 2024</span>
          </Box>
        );

      default:
        break;
    }
  };

  return (
    <Box className={classes.ufcRoundInfo}>
      <Box className={clsx(classes.ufcRoundPlayer)}>
        <Box className={classes.ufcRoundPlayerInfo}>
          <Box className="player-details">
            {loadingRoundDetails ? (
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{ bgcolor: "#ffffff1c" }}
                width={60}
                height={48}
              />
            ) : (
              <span className="player-bet-multiplier">
                {parseFloat(
                  toUSDFormat(
                    currentRoundDetails.player2TotalUsd /
                      currentRoundDetails.player1TotalUsd +
                      1,
                    2
                  ).toString()
                ).toFixed(2)}
                <Box className="player-bet-border"></Box>
              </span>
            )}

            {renderPlayerInfo(UFCPlayerType.PLAYER1)}
          </Box>

          {renderPlayerPhoto(UFCPlayerType.PLAYER1)}

          {renderWinnerTag(UFCPlayerType.PLAYER1)}

          <Box
            sx={{
              width: "100%",
              height: 6,
              position: "absolute",
              bottom: 0,
              background: "-webkit-linear-gradient(315deg, #ff9f9f, #ff0000)",
            }}
          ></Box>
        </Box>

        {renderRoundAction(UFCPlayerType.PLAYER1)}
      </Box>

      <Box className={classes.ufcRoundSummary}>
        {renderEventSummary()}

        <Box className="ufc-round-pool">
          <span>Pool value</span>

          {loadingRoundDetails ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={{ bgcolor: "#ffffff1c" }}
              width={60}
              height={35}
            />
          ) : (
            <span>${toUSDFormat(currentRoundDetails.totalUsd)}</span>
          )}
        </Box>
      </Box>

      <Box className={classes.ufcRoundPlayer}>
        <Box className={clsx(classes.ufcRoundPlayerInfo, "right")}>
          <Box className="player-details">
            {loadingRoundDetails ? (
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{ bgcolor: "#ffffff1c" }}
                width={60}
                height={48}
              />
            ) : (
              <span className="player-bet-multiplier">
                {parseFloat(
                  toUSDFormat(
                    currentRoundDetails.player1TotalUsd /
                      currentRoundDetails.player2TotalUsd +
                      1,
                    2
                  ).toString()
                ).toFixed(2)}

                <Box className="player-bet-border"></Box>
              </span>
            )}

            {renderPlayerInfo(UFCPlayerType.PLAYER2)}
          </Box>

          <Box className="player-photo-mask">
            {renderPlayerPhoto(UFCPlayerType.PLAYER2)}
          </Box>
          {renderWinnerTag(UFCPlayerType.PLAYER2)}

          <Box
            sx={{
              width: "100%",
              height: 6,
              position: "absolute",
              bottom: 0,
              background: "-webkit-linear-gradient(315deg, #51bfff, #4285ec)",
            }}
          ></Box>
        </Box>

        {renderRoundAction(UFCPlayerType.PLAYER2)}
      </Box>

      <Popover
        id={popupId}
        open={popOpen}
        anchorEl={popupAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        classes={{ paper: "plinko-coin-list-popover" }}
      >
        <TokenListPopup handleSelectToken={handleSelectToken} />
      </Popover>
    </Box>
  );
}
