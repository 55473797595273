export const gameConfig = {
  chainId: 369,
  chainIDHex: 0x171,
  chainSymbol: "PLS",
  chainName: "Pulsechain",
  explorerUrl: "https://otter.pulsechain.com/",
  // providerList: ["https://rpc-pulsechain.g4mm4.io"],
  providerList: ["https://rpc.pulsechain.com"],
  serverUrl: "https://backend.pulsepredict.io/",
  dummyServerUrl: "https://backend.pulsepot.io/",
  testServerUrl: "https://54q31jdb-3000.usw3.devtunnels.ms/",

  plspTokenAddress: "0xC52F739f544d20725BA7aD47Bb42299034F06f4F",

  // contract addresses
  marchMadnessAddress: "0x99f2830B745AD86F09432FDB09F91cd9c6a3d43A",
  uefa24Address: "0xb74178f5bB0E92B9d0A2E1d69A3d877508F8Ac56",
  plspStakingAddress: "0xa93d952095994113E3d28df04931C8D00b82E75C",
  predictAddress: "0x006490bb2317900cAc19A7Fc4580B8446a90c8eb",
  euro24Address: "0x6d7799C6Cf8BadF04A81e454C299A7E9f245D1e7",

  ufcAddress: "0x0005F0b4439Cf29bFf137a868e62987EBD79A177",
  ifcAddress: "0x64982A00D401858A59a9d14e4811aB794287AC31",
  ufc300Address: "0x968537338B0Bc78c0160A80c694C4C7004A91A5b",
  daznAddress: "0x7AAFB8adefdc70feb718a0F718E9FAF613DA135D",

  electionAddress: "0x267197FdB5BD86331D4d067840F2BDA856Cc7B6a",

  tysonAddress: "0x4B6FA72d8EC5917A5D83D3e9532c845a5ffd7DF9",

  admins: [
    "0xe7CDbD37AA3d10942e08B2d8dC1406f854EEC21f",
    "0x261C6DB8dAe6dAF310d564C27e8E88c5681088B5",
    "0x2354248fa3C622af997AB7Edb98B3BC35551f04A",
    "0xa30f2B0174D2cAeAc945a3DA146D9D0Ee7D6eDB4",
  ],
};
