import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  ufcRoundInfo: {
    marginTop: "64px",
    display: "grid",
    gridTemplateColumns: "38fr 24fr 38fr",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      maxWidth: "350px",
      margin: "auto",
      marginTop: "64px",
    },
  },

  ufcRoundPlayerInfo: {
    position: "relative",
    height: "140px",
    // borderBottom: "8px solid #7a3884",
    background: [
      "rgb(57, 59, 71)",
      "linear-gradient( 90deg, #1b1f28 0%, rgba(255, 255, 255, 0) 100%)",
    ],
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
    },

    "& .player-photo": { position: "absolute", bottom: "0", left: "-48px" },
    "& .player-details": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      position: "absolute",
      right: "24px",
      top: "8px",
      userSelect: "none",
      zIndex: 1,
      "& .player-bet-multiplier": {
        fontSize: "32px",
        fontWeight: 600,
        color: "#f772d1",
        background: "-webkit-linear-gradient(315deg, #ff9f9f, #ff0000)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        display: "flex",
        "& .player-bet-border": {
          width: "2px",
          height: "18px",
          backgroundColor: "#5e679f",
          cssFloat: "right",
          marginLeft: "12px",
          marginTop: "16px",
        },
      },
      "& .player-social": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        span: { color: "#5e679f", fontSize: "14px", fontWeight: 600 },
        "& a": {
          height: "16px",
        },
      },
      "& .player-name": {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        display: "block",
        overflow: "hidden",
        color: "white",
        fontSize: "30px",
        fontWeight: 600,
        zIndex: 1,
        marginTop: "8px",
        filter: "drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 6px black)",
        [theme.breakpoints.down("xl")]: {
          fontSize: "26px",
        },

        [theme.breakpoints.down("lg")]: {
          fontSize: "24px",
        },

        [theme.breakpoints.down("md")]: {
          fontSize: "24px",
          maxWidth: "200px",
        },

        [theme.breakpoints.down("sm")]: {
          fontSize: "24px",
          maxWidth: "initial",
        },
      },
    },

    "&.right": {
      // borderBottom: "2px solid #312e73",
      background:
        "linear-gradient(270deg,#1b1f28 0%,rgba(255, 255, 255, 0) 100% )",

      "& .player-details": {
        right: "initial",
        left: "24px",
        alignItems: "flex-start",
        "& .player-bet-multiplier": {
          color: "#1fa7f6",
          background: "-webkit-linear-gradient(315deg, #51bfff, #4285ec)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          flexDirection: "row-reverse",
          "& .player-bet-border": {
            cssFloat: "left",
            marginRight: "12px",
            marginLeft: "0",
          },
        },
        "& .player-social": { flexDirection: "row-reverse" },
      },
      "& .player-photo-mask": {
        marginRight: "-16px",
        width: "252px",
        height: "250px",
        position: "absolute",
        bottom: "0",
        right: "0px",
        overflow: "hidden",
        "& .player-photo": {
          position: "absolute",
          bottom: "0",
          left: "initial",
          right: "-24px",
        },
      },
    },
  },

  ufcWinnerTag: {
    fontSize: "48px",
    color: "white",
    textShadow:
      "0px 0px 20px #1daa70, 0px 0px 40px #1daa70,\n        0px 0px 60px #1daa70, 0px 0px 80px #1daa70, 0px 0px 100px #1daa70",
    position: "absolute",
    top: "-64px",
    right: "16px",

    "&.right": {
      left: "16px",
    },
  },

  ufcDrawTag: {
    fontSize: "48px",
    color: "white",
    textShadow:
      "0px 0px 20px #ff6c00, 0px 0px 40px #ff6c00,\n        0px 0px 60px #ff6c00, 0px 0px 80px #ff6c00, 0px 0px 100px #ff6c00",
    position: "absolute",
    top: "-64px",
    right: "16px",

    "&.right": {
      left: "16px",
    },
  },

  ufcRoundPlayer: {
    "&:last-of-type": {
      "& .ufc-round-actions": { justifyContent: "flex-end" },
    },
  },

  ufcRoundActions: {
    display: "flex",
    gap: "8px",

    "&.right": {
      justifyContent: "flex-end",
    },

    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",

      "&.right": {
        justifyContent: "center",
      },
    },
  },

  ufcRoundToken: {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "0",
    minWidth: "0",
    color: "white",
    backgroundColor: "#1b1f28",
    marginTop: "24px",
    fontSize: "32px",
    fontWeight: 600,

    "& img": {
      width: "28px",
      height: "28px",
    },
  },

  ufcRoundTokenSelectedIndicator: {
    position: "absolute",
    bottom: "-8px",
    animation: "night-fight-select-token-animation 1s infinite ease-in-out",
    height: "24px",

    "& img": {
      width: "24px",
      height: "24px",
      transform: "rotate(270deg)",
    },

    "@keyframes night-fight-select-token-animation": {
      "0%": { bottom: "-24px" },
      "50%": { bottom: "-8px" },
      "100%": { bottom: "-24px" },
    },
  },

  ufcRoundButton: {
    color: "white",
    backgroundColor: "#27ae78",
    borderRadius: "16px",
    display: "flex",
    fontWeight: 600,
    padding: "8px 16px",
    marginTop: "24px",

    "& img": {
      marginRight: "8px",
      width: "16px",
      height: "16px",
    },

    "&:disabled": { backgroundColor: "#1b1f28" },
  },

  ufcRoundBetButton: {
    minWidth: "140px",
  },

  createButton: {
    minWidth: "100px!important",
  },

  ufcRoundCancelButton: {
    minWidth: "82px",
    background: "linear-gradient(45deg, #ff887c, #b6325f)",
  },

  ufcRoundClosedButton: {
    background: "linear-gradient(45deg, #ff887c, #b6325f)",
  },

  ufcRoundFinishedButton: {
    background: "linear-gradient(45deg, #ff887c, #b6325f)",
  },

  ufcRoundFinishedTx: {
    "& .MuiButton-label": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },

  ufcRoundQuickBet: {
    color: "white",
    fontWeight: 700,
    backgroundColor: "#1b1f28",
    border: "2px solid #373b45",
    padding: "8px",
    borderRadius: "8px 4px",
    fontSize: "12px",
    cursor: "pointer",
    height: "40px",
    marginTop: "24px",
    minWidth: "0",
    width: 40,

    "& span": {
      minWidth: "0",
    },

    [theme.breakpoints.down("sm")]: {
      minWidth: "0!important",
      padding: "8px 4px",
      width: "40px!important",
    },
  },

  ufcInputWithPrefix: {
    display: "flex",
    position: "relative",
    marginTop: "24px",

    "& span": {
      color: "white",
      padding: "8px 0 8px 16px",
      fontWeight: 900,
      position: "absolute",
      fontSize: "16px",

      [theme.breakpoints.down("sm")]: {
        padding: "10px 0 8px 8px",
      },
    },

    "& .bet-usd-clear": {
      position: "absolute",
      right: "16px",
      cursor: "pointer",
      color: "rgba(255, 255, 255, 0.5)",
      transition: "300ms",
      "&:hover": { color: "#cd72ee" },
      [theme.breakpoints.down("sm")]: {
        right: 7,
        bottom: "-15px",
        fontSize: "10px",
        padding: 0,
      },
    },

    "& .bet-usd-max-label": {
      color: "rgba(255, 255, 255, 0.15)",
      position: "absolute",
      fontWeight: 100,
      bottom: "-32px",
      cursor: "pointer",
      transition: "300ms",
      "&:hover": { color: "#cd72ee" },

      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
        bottom: "-30px",
        padding: 0,
        display: "none",
      },
    },

    "& .bet-usd-input": {
      width: "144px",
      borderRadius: "16px",
      backgroundColor: "#1b1f28",
      border: "none",
      outline: "none",
      padding: "10px 32px 8px 40px",
      color: "white",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      "&::-webkit-inner-spin-button": { display: "none" },

      [theme.breakpoints.down("sm")]: {
        width: "60px",
        padding: "12px 8px 8px 24px",
      },
    },
  },

  ufcRoundSummary: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
    marginTop: "-24px",

    "& .ufc-round-vs": {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      color: "#797678",
      fontWeight: 600,
      textAlign: "center",
      "& .vs": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        span: {
          color: "white",
          fontSize: "28px",
          fontWeight: 600,
          textShadow:
            "0px 0px 20px rgb(250, 5, 161),  0px 0px 40px rgb(250, 5, 161), 0px 0px 60px rgb(250, 5, 161),  0px 0px 80px rgb(250, 5, 161), 0px 0px 100px rgb(250, 5, 161)",
          filter: "drop-shadow(0px 0px 100px rgb(250, 5, 161))",
        },
      },
    },
    "& .ufc-round-pool": {
      padding: "16px 40px",
      gap: "12px",
      borderRadius: "12px",
      border: "1px solid #3a3c47",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      maxWidth: "240px",
      "span:first-of-type": { fontSize: "14px", color: "#998a37" },
      "span:last-of-type": {
        color: "white",
        fontSize: "28px",
        fontWeight: 600,
        textShadow: "0px 0px 50px rgba(182, 106, 16, 1)",
      },
    },
  },

  ufcLoadingSpinner: {
    width: "24px !important",
    height: "24px !important",
  },
}));

export default styles;
